<template>
  <div>
    <address-filter :reset="reset" @reset="setting_reset" />
    <div class="row">
      <div class="style-col">
        <b-form-group>
          <input type="text" class="form-control" v-model="search" placeholder="Nhập nội dung" />
        </b-form-group>
      </div>
      <div class="col-lg-7">
        <div class="row">
          <div class="col-lg-4" style="margin-bottom: 15px; cursor: pointer">
            <b-form-select v-model="category" aria-placeholder="a" style="cursor: pointer">
              <b-form-select-option :value="null" disabled>Danh mục</b-form-select-option>
              <option v-for="cat in categories" :key="cat.id" :value="cat.name">
                {{ cat.name }}
              </option>
            </b-form-select>
          </div>
          <div class="col-lg-4" style="margin-bottom: 15px">
            <b-form-select v-model="trangThai" aria-placeholder="a" style="cursor: pointer">
              <b-form-select-option :value="null" disabled>Trạng thái</b-form-select-option>
              <b-form-select-option value="Chờ xử lý">Chờ xử lý</b-form-select-option>
              <b-form-select-option value="Đang xử lý">Đang xử lý</b-form-select-option>
              <b-form-select-option value="Đã xử lý">Đã xử lý</b-form-select-option>
              <b-form-select-option value="Không xử lý">Không xử lý</b-form-select-option>
              <b-form-select-option value="Quá hạn xử lý">Quá hạn xử lý</b-form-select-option>
            </b-form-select>
          </div>
          <div v-if="role_login==3 || role_login==4" class="col-lg-4" style="margin-bottom: 15px">
            <b-form-select v-model="phuTrach" style="cursor: pointer">
              <b-form-select-option :value="null" disabled>Phụ trách</b-form-select-option>
              <b-form-select-option :value="valuePhuTrach" v-if="role_login==3 || role_login==4" class="style-me">------
                Tôi ------</b-form-select-option>
              <option v-for="user in underUsers" :key="user.id" :value="user.id">
                {{ user.name }} - ({{ user.position }})
              </option>
            </b-form-select>
          </div>
        </div>
      </div>
      <div class="col-lg-2" :class="{'style-pos':role_login!=3 && role_login!=4}">
        <b-button variant="light" class="btn-refresh" @click="refresh" title="Xóa tìm kiếm">
          <i class="fas fa-sync"></i>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import addressFilter from "../../../../components/filter/address-filter.vue";
import { httpClient } from "../../../../_utils/httpClient";
import { Base64 } from "js-base64";

export default {
  name: "product-filter",
  components: {
    addressFilter,
  },
  data() {
    return {
      category: null,
      categories: [],
      trangThai: null,
      search: "",
      phuTrach: null,
      underUsers: [],
      valuePhuTrach: "",
      reset: false,
      role_login: null
    };
  },
  watch: {
    category: {
      handler: function () {
        let query = {
          ...this.$route.query,
        };
        if (this.category) {
          query.category = this.category;
        } else {
          delete query.category;
        }
        this.$router.push({
          name: this.$route.name,
          query: query,
        });
      },
      deep: true,
    },
    trangThai: {
      handler: function () {
        let query = {
          ...this.$route.query,
        };
        if (this.trangThai) {
          query.trangThai = this.trangThai;
        } else {
          delete query.trangThai;
        }
        this.$router.push({
          name: this.$route.name,
          query: query,
        });
      },
      deep: true,
    },
    search: {
      handler: function () {
        let query = {
          ...this.$route.query,
        };
        if (this.search == '') {
          delete query.search
          this.$router.push({
            name: this.$route.name,
            query: query,
          });
        }
        if (this.search.length > 0) {
          query.search = this.search;
          setTimeout(() => {
            if (query.search == this.search || (this.search.length < query.search.length && query.search.length == this.search.length)) {
              this.$router.push({
                name: this.$route.name,
                query: query,
              });
            }
          }, 800);
        } else {
          delete query.search;
        }
      },
      deep: true,
    },
    phuTrach: {
      handler: function () {
        let query = {
          ...this.$route.query,
        };
        if (this.phuTrach) {
          query.phuTrach = this.phuTrach;
        } else {
          delete query.phuTrach;
        }
        this.$router.push({
          name: this.$route.name,
          query: query,
        });
      },
      deep: true,
    },
  },
  methods: {
    setting_reset() {
      this.reset = false
    },
    getData() {
      this.getCategories();
      this.getUnderUser();
      let userData = localStorage.getItem("token").split(".")[1];
      let userInfo = JSON.parse(Base64.decode(userData));
      this.valuePhuTrach = userInfo.phone;
      this.role_login = userInfo.role_level
    },
    async getCategories() {
      let response = await httpClient.get(`config`, {
        params: {
          name: "category",
        },
      });
      this.categories = response.data.data;
    },
    async getUnderUser() {
      let response = await httpClient.get(`user/under`);
      let responseData = response.data;
      if (responseData.code === 0) {
        this.underUsers = responseData.data;
      }
    },
    async refresh() {
      Object.assign(this.$data, this.$options.data.apply(this));
      this.reset = true
      // Hàm có chức năng sao chép data ban đầu vào data hiện tại
      // this.$data: tất cả các giá trị của data hiện tại
      // this.$options.data.apply(this): tất cả các giá trị của data lúc khởi tạo ban đầu
      this.getData();
    },
    getSearch() {
      console.log(this.$route.query,"query");
      let query = this.$route.query
      if (query.search) {
        this.search = query.search
      }
      if (query.category) {
        this.category = query.category
      }
      if (query.phuTrach) {
        this.phuTrach = query.phuTrach
      }
      if (query.trangThai) {
        this.trangThai = query.trangThai
      }
    }
  },
  async created() {
    await this.getData();
    this.getSearch();
  },
};
</script>

<style scoped>
@media (max-width: 991px) {
  .style-col {
    width: 100% !important
  }

  .style-pos {
    transform: translateX(0) !important;
  }
}

.style-me {
  font-size: 16px;
  font-weight: bold;
  margin-left: 30px;
}

.style-col {
  margin: 0 12px;
  width: calc(18% - 6px)
}

.style-pos {
  transform: translateX(calc(-100% - 30px));
}
</style>
